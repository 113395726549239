import React, { useEffect } from 'react'
import { Layout, theme, Alert, Tooltip, Popconfirm, Card, Space, Pagination, Dropdown, Empty, } from 'antd'
import { PlusCircleOutlined, UsergroupAddOutlined ,FileDoneOutlined} from '@ant-design/icons';
import HeaderScreen from '../components/common/HeaderScreen'
import { Content } from 'antd/es/layout/layout'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import TSTable from '../components/common/TSTable';
import { PATH } from '../routes/CustomRoutes';
import { BG_LAYOUT_COLOR, SECONDARY_COLOR } from '../constants/color';
import { DELETE, GET } from '../frameworks/HttpClient';
import { URL_MEMBER, URL_PLANTATIONS } from '../constants/urls';
import WorkerListModal from '../components/farmer/WorkerListModal';
import { LANGUAGE, PLANTATION_TRACEABILITY, PRODUCT_TRACEABILITY } from '../constants/string';
import { useWindowDimensionContext } from '../hooks/WindowDimensionProvider';
import Item from 'antd/es/list/Item';
import { List ,Button} from 'antd-mobile'
import Meta from 'antd/es/card/Meta';
import ColorButton from '../components/common/ColorButton';
import { Modal } from 'antd-mobile'
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';



export default function FarmerPlantationsScreen() {
  const params = useParams();
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [farmerId, setFarmerId] = React.useState(null);
  const tableRef = React.useRef(null); 
  const [farmer, setFarmer] = React.useState(""); 
  const [target, setTarget] = React.useState(null);
  const [dataFarmerPlantation , setDataFarmerPlantation] = React.useState([]);
  const { lessThanOrEqual } = useWindowDimensionContext();
  const isOnMobile = lessThanOrEqual('md');
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1)
  const [visible, setVisible] = React.useState(false)
  const DEFAULT_PAGE_SIZE = 10;
  const { t, i18n } = useTranslation();


  const FARMER_STATUS = {
    draft: t("farmer_status.draft"), 
    checking: t("farmer_status.checking"), 
    waiting: t("farmer_status.waiting"), 
    approved: t("farmer_status.approved"), 
    rejected: t("farmer_status.rejected"), 
  }

  const readerCard = (item) =>{
    let data = [
    <EditOutlined  
      onClick={() => navigate(`${PATH.PLANTATION_DETAIL}/${params.id}/${item.id}`)} 
    />,
    (item.use_worker === true ?
    <Tooltip title={t("rubber_tapping_contractor")}>
      <FileDoneOutlined 
        onClick={() => setTarget(item.id)} 
      />
    </Tooltip> : null),
    <Popconfirm
      title={t("farmers.delete_title")}
      description={t("confirm_delete_member")}
      onConfirm={() => handleDelete(item.id)}
      okText={t("delete")}
      okButtonProps={{ danger: true }}
      cancelText={t("cancel")}
    >
      <DeleteOutlined />
    </Popconfirm>
    ]

    let filtered = _.filter(data, v => v !== null);
    return filtered;
  }
  

  const refreshData = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.fetch();
    }
  }

  const handleDelete = async (id) => {
    await DELETE(`${URL_PLANTATIONS.PLANTATIONS}${id}/`)
    refreshData();
  }

  const fetchData = async () => {
    if (!params || !params.id) {
      setErrorMessages(t("message"))
    }
    try {
      const respones = await GET(`${URL_MEMBER.FARMER}${params.id}`)
      setFarmer(respones.data)
    } catch (error) {
      
    }
  }
  const fetchDataFarmerPlantation = async(page) => {
    const response = await GET(`${URL_PLANTATIONS.PLANTATIONS}?farmer=${params.id}&page_size=${DEFAULT_PAGE_SIZE}&page=${page ? page : 1}`)
    setDataFarmerPlantation(response.data.results)
    setTotal(response.data.total)
  }
  const {
		token: { colorBgContainer, colorWarning, colorError, colorInfo },
	} = theme.useToken();

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: t("plantations.deed_type"),
      dataIndex: "title_deed_type_name",
      sorter: true,
    },
    {
      title: t("document_number"),
      dataIndex: "title_deed_no",
      searcher: true,
    },
    {
      title: t("plot_code"),
      dataIndex: "code",
      searcher: true,
    },
    {
      title: t("type"),
      dataIndex: "traceability",
      render: (value,original) =>{
        return(
          <p>{original.traceability === PLANTATION_TRACEABILITY.FSC ? 'FSC' : 'CT'}</p>
          // <p>{value ? value.toUpperCase() : ""}</p>
        )
      }
    },
    {
      title: t("status"),
      width: 160,
      dataIndex: 'status',
      render: (value) => (FARMER_STATUS[value])
    },
    {
      title: 'Action',
      width: 160,
      dataIndex: 'state',
      render: (value, original) => {
        return (
          <div style={{textAlign: "center"}}>
            <Space>
            <Tooltip title={t("actions.edit")}>
              <ColorButton 
                type={"primary"}
                override={colorWarning}
                icon={<EditOutlined />}
                onClick={() => navigate(`${PATH.PLANTATION_DETAIL}/${params.id}/${original.id}`)} />
            </Tooltip>
            {original.use_worker === true &&<Tooltip title={t("rubber_tapping_contractor")}>
              <ColorButton 
                type={"primary"}
                override={colorWarning}
                icon={<UsergroupAddOutlined />}
                onClick={() => 
                  setTarget(original.id)
                } />
            </Tooltip>}
            <Popconfirm
              title={t("farmers.delete_title")}
              description={t("confirm_delete_member")}
              onConfirm={() => handleDelete(original.id)}
              okText={t("delete")}
              okButtonProps={{ danger: true }}
              cancelText={t("cancel")}>
              <ColorButton 
                type={"primary"}
                override={colorError} 
                icon={<DeleteOutlined />}
                onClick={() => null} />
            </Popconfirm>
            </Space>
          </div>
        )
      }
    }
  ]

  const items =  [
    ((farmer && farmer.is_fsc === true) && {
      key: '1',
      label: (
        <a onClick={() => navigate({
          pathname : `${PATH.PLANTATION_DETAIL}/${params.id}`,
          search: createSearchParams({
            type : 'fsc',
          }).toString()
         })}>
          {t("fsc_plot")}
        </a>
      ),
    }),
     {
      key: '2',
      label: (
        <a onClick={() => navigate({
          pathname : `${PATH.PLANTATION_DETAIL}/${params.id}`,
          search: createSearchParams({
            type : 'ct',
          }).toString()
         })}>
          {t("ct_plot")}
        </a>
      ),
    },
  ]

  React.useEffect(() => {
    fetchData();
    fetchDataFarmerPlantation();
  },[])

  return (
    <Layout style={{ minHeight: "100vh", background: BG_LAYOUT_COLOR }}>
      <HeaderScreen title={farmer.full_name ? `${t("menu.plantations")} [${i18n.language === LANGUAGE.TH ? farmer.full_name : farmer.full_name_en}]` : t("menu.plantations")} />
      <Content 
        style={{ 
          margin: "0px 24px 24px 24px", 
          padding: "16px 24px",
          textAlign: "left",  
          minHeight: 280,
          background: colorBgContainer }}>
        {errorMessages && 
          <Alert
            message={errorMessages}
            type="error"
            showIcon
          />
        }
        {isOnMobile ? (
          <div style={{textAlign:'center'}}>
            <Button 
              block color='warning'
              size='large'
              onClick={handleOpenModal}
            >
              {t("add_plot")}
            </Button> 
            <br/>
            { !dataFarmerPlantation.length && <Empty description='ไม่มีข้อมูล'/> }
            <Modal
              visible={visible}
              onClose={handleCloseModal}
              closeOnAction
              title={t("add_a_rubber_plantation_plot")}
              content={
                <div style={{ textAlign: 'center' }}>
                  <Space direction='vertical'>
                    {farmer && farmer.is_fsc === true &&
                    <Button
                      block
                      color='success'
                      fill='outline'
                      size='large'
                      onClick={() =>
                        navigate({
                          pathname: `${PATH.PLANTATION_DETAIL}/${params.id}`,
                          search: createSearchParams({
                            type: 'fsc',
                          }).toString(),
                        })
                      }
                    >
                      {t("fsc_plot")}
                    </Button>}
                    <Button
                      block
                      color='success'
                      fill='outline'
                      size='large'
                      onClick={() =>
                        navigate({
                          pathname: `${PATH.PLANTATION_DETAIL}/${params.id}`,
                          search: createSearchParams({
                            type: 'ct',
                          }).toString(),
                        })
                      }
                    >
                     {t("ct_plot")}
                    </Button>
                  </Space>
                </div>
              }
              actions={[
                {
                  key: 'Close',
                  text: 'Close',
                },
              ]}
            />
          </div>
        ) : (
          <div style={{marginTop: 16}}>
            <Dropdown
              trigger={['click']}
              menu={{
                items,
              }}
              placement="bottom"
            >
              <ColorButton 
                type={"primary"} 
                override={SECONDARY_COLOR}
                icon={<PlusCircleOutlined />} 
                style={{float: "right", background: SECONDARY_COLOR, marginBottom: 16}}
              >
                {t("add_plot")}
              </ColorButton>
            </Dropdown>
          </div>
        )}
        {isOnMobile ? (
          <div>
            <List>
              {dataFarmerPlantation && dataFarmerPlantation.map(item => (
                <List.Item key={item.id}>
                  <Card
                    title={<p>{t("plantations.deed_type")} {item.title_deed_type_name}</p>}
                    actions= {readerCard(item)}
                  >
                    <Meta
                      description={
                        <>
                          <>{t("plantations.deed_code")}: {item.title_deed_no}</>
                          <br/>
                          <>{t("plot_code")}: {item.code}</>
                          <br/>
                          <>{t("status")}: {item.status && item.status !== 'none' ? FARMER_STATUS[item.status] : t("farmer_status.none")}</>
                        </>
                      }
                    />
                  </Card>
                </List.Item>
              ))}
            </List>
            <br/>
            <div style={{textAlign:'center'}}>
              <Pagination
                defaultPageSize={DEFAULT_PAGE_SIZE}
                defaultCurrent={1}
                current={page}
                total={total}
                onChange={(page) => {
                  setPage(page)
                  fetchDataFarmerPlantation(page)
                }}
              />
            </div>
          </div>
        ) : (
          <TSTable
            ref={tableRef}
            columns={columns}
            url={`${URL_PLANTATIONS.PLANTATIONS}?farmer=${params.id}`}
            rowKey={"id"}
            size={"small"}
            pageSize={10}
          />
        )}
      </Content>
      <WorkerListModal
        open={target ? true : false}
        onClose={() => setTarget(null)}
        plantId={target}
      />
    </Layout>
  )}
  